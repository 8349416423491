import { StaticImage } from "gatsby-plugin-image"
import React from "react"

import { pagesLinks } from "../../../pages-data/_V2/common/links"
import styles from "./styles.module.scss"

export default {
	desktopTitle: (
		<span>Автоматизация бара&nbsp;</span>
	),
	price: "от 2490 ₽/мес",
	subTitle:
		"Quick Resto — программа для бара, с системой учёта алкоголя, инструментами для привлечения гостей и контроля персонала. Кассовый терминал Quick Resto позволяет принимать до 10 заказов в минуту.",
	figure: (
		<StaticImage
			className={styles.image}
			src="./assets/bar-presentation.png"
			alt="автоматизация бара"
			objectFit="contain"
			objectPosition="center"
			placeholder="blurred"
			quality={90}
		/>
	),
	cta: pagesLinks.registration.text,
	ctaLink: pagesLinks.registration,
	desktopCtaDetails:
		"За 10 минут, без дорогого оборудования, с удобным интерфейсом. И две недели бесплатно.",
	mobileCtaDetails:
		"За 10 минут, без дорогого оборудования, с удобным интерфейсом. И две недели бесплатно.",
}
